import axios from 'axios'
import router from "@/router";

const request = axios.create({
    baseURL: process.env.VUE_APP_BASEURL,
    timeout: 30000
})

//request拦截器
//可以自请求发送前对请求做一些处理
//比如统一加token,对请求参数统一加密
request.interceptors.request.use(config =>{
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
    let admin = JSON.parse(localStorage.getItem("recentAdmin") || '{}')
    config.headers['token'] = admin.token; //设置请求头
    return config
},error => {
    console.error('request error: ' + error)
    return Promise.reject(error)
});

//response 拦截器
request.interceptors.response.use(
    response =>{
        let res = response.data;//兼容服务端返回的字符串数据
        if(typeof res === 'string'){
            res = res ? JSON.parse(res) : res
        }
        if (res.code ==='401'){
             router.push('/login')
            alert("请登录")
        }
        return res;
    },
    error => {
        console.error('response error: ' + error)
        return Promise.reject(error)
    }
)

export default request