import Vue from 'vue'
import VueRouter from 'vue-router'

//解决单击导航栏路径频繁报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'manager',
        component: () => import('../views/Frame.vue'),
        redirect: '/index',
        children: [
            {path: '403', name: '无权访问', component: () => import('../views/Auth.vue')},
            {path: 'userInfo', name: '用户信息', component: () => import('../views/manager/UserInfo.vue')},
            {path: 'index', name: '系统首页', component: () => import('../views/manager/Index.vue')},
            {path: 'lawyerInfo', name: '律师信息', component: () => import('../views/manager/LawyerInfo.vue')},
            {path: 'newsInfo', name: '新闻信息', component: () => import('../views/manager/NewsInfo.vue')},
            {path: 'articleInfo', name: '法条信息', component: () => import('../views/manager/ArticlesInfo.vue')},
            {path: 'consultationInfo', name: '咨询信息', component: () => import('../views/manager/ConsultationInfo.vue')},
            {path: 'adminInfo', name: '管理员信息', component: () => import('../views/manager/AdminInfo.vue')},
            {path: 'personInfo', name: '个人信息', component: () => import('../views/manager/PersonInfo.vue')},
            {path: 'resetPassword', name: '修改密码', component: () => import('../views/manager/ResetPassword.vue')},
            {path: 'newsDetail', name: '新闻详情', component: () => import('../views/manager/NewsDetail.vue')},
        ]
    },
    {path: '/login', name: 'login', component: () => import('../views/Login.vue')},
    {path: '/register', name: 'register', component: () => import('../views/Register.vue')},
    {path: '/*', name: '404', component: () => import('../views/404.vue')},
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


//路由守卫
router.beforeEach((to, from, next) => {
    let loginAdmin = JSON.parse(localStorage.getItem('recentAdmin') || '{}')
    if (loginAdmin.authority && !loginAdmin.authority.includes('用户管理') && to.path === '/userInfo') {
        next('/403')
    }
    if (loginAdmin.authority && !loginAdmin.authority.includes('咨询管理') && to.path === '/consultationInfo') {
        next('/403')
    }
    if (loginAdmin.authority && !loginAdmin.authority.includes('管理员管理') && to.path === '/adminInfo') {
        next('/403')
    } else {
        next()
    }

})


export default router
